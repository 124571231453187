import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Work Experience`}</h2>
    <ProjectCard title="Facebook" link="https://www.facebook.com/" bg="linear-gradient(to top, #00d2ff, #3a7bd5);" mdxType="ProjectCard">
Production Engineer Intern - Release to Production team
    </ProjectCard>
    <ProjectCard title="NVIDIA" link="https://www.nvidia.com/en-us/" bg="linear-gradient(to right, #56ab2f, #a8e063);" mdxType="ProjectCard">
Software Engineer Intern - Process Orchestration team
    </ProjectCard>
    <ProjectCard title="Tesla" link="https://www.tesla.com/" bg="linear-gradient(to top, #ed213a, #93291e);" mdxType="ProjectCard">
Software Engineer Intern - Powertrain & Supercharger team
    </ProjectCard>
    <ProjectCard title="ServiceNow" link="https://www.servicenow.com/" bg="linear-gradient(to top, #44a08d, #093637);" mdxType="ProjectCard">
Software Engineer Intern - Imaging & Systems Validation team
    </ProjectCard>
    <ProjectCard title="Harvesthru" link="https://www.linkedin.com/company/harvesthru/" bg="linear-gradient(to bottom, #00c9ff, #92fe9d);" mdxType="ProjectCard">
Software Engineer Intern - E-commerce & ransactions team
    </ProjectCard>
    <ProjectCard title="Precision for Medicine" link="https://www.precisionformedicine.com/" bg="linear-gradient(to bottom, #de6262, #ffb88c)" mdxType="ProjectCard">
Software Testing Intern - Database Management Team
    </ProjectCard>
    <ProjectCard title="ACM at UCSD" link="https://acmucsd.com/" bg="linear-gradient(to bottom, #0052d4, #4364f7, #6fb1fc);" mdxType="ProjectCard">
Software Development Team & Technical Event Director
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      