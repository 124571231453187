import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`I hope to change the world one line of code at at a time`}</h4>
    <p>{`Passionate and driven Software Engineer with a specialization in Full Stack Web Development and Cloud Infrastructure. I hope that the
technology that I create reflects my love for breeding innovation and breaking down complex problems to their very core.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      