import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Let's get in touch :)`}</h2>
    <p>{`Reach out to me on `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/jadenpadua/"
      }}>{`LinkedIn`}</a>{`, `}<a parentName="p" {...{
        "href": "mailto:paduajaden@gmail.com"
      }}>{`Email`}</a>{`, or `}<a parentName="p" {...{
        "href": "https://github.com/jadenpadua"
      }}>{`Github`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      